<template>
  <v-container id="regular-tables" fluid>
    <v-data-table
      :headers="headers"
      :items="promotions"
      :loading="isLoadingList"
      :server-items-length="promotionsMeta.totalItems"
      :options.sync="options"
      :items-per-page="10"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('promotion-update')"
          small
          class="mr-2"
          @click="viewItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon v-if="$admin.can('promotion-view')" small class="mr-2">
          mdi-content-copy
        </v-icon>
      </template>
      <template v-slot:item.type="{ item }">
        {{ promotion_types.find((type) => type.value === item.type)?.text }}
      </template>
      <template v-slot:item.status="{ item }">
        {{
          promotion_statuses.find((status) => status.value === item.status)
            ?.text
        }}
      </template>
      <template v-slot:top>
        <div style="gap: 10px" class="d-flex mx-2 mt-2">
          <v-text-field
            outlined
            dense
            v-model="options.search"
            :label="$t('search')"
            clearable
            style="flex: 1 1 0"
          ></v-text-field>
          <v-menu
            ref="start_date"
            v-model="start_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDateFormatted"
                outlined
                dense
                clearable
                :label="$t('start_date')"
                persistent-hint
                append-icon="mdi-calendar"
                v-bind="attrs"
                style="flex: 1 1 0"
                @blur="date = parseDate(startDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="options.start_date"
              no-title
              @input="start_date_menu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="end_date"
            v-model="end_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDateFormatted"
                outlined
                dense
                clearable
                :label="$t('end_date')"
                append-icon="mdi-calendar"
                v-bind="attrs"
                @blur="date = parseDate(endDateFormatted)"
                v-on="on"
                style="flex: 1 1 0"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="options.end_date"
              no-title
              @input="end_date_menu = false"
            ></v-date-picker>
          </v-menu>
          <v-select
            outlined
            dense
            clearable
            v-model="options.type"
            :items="promotion_types"
            :label="$t('type')"
            style="flex: 1 1 0"
          ></v-select>
        </div>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { promotionStatues, promotionTypes } from "../utils/values";

export default {
  components: {},

  computed: {
    ...mapGetters({
      isLoadingList: "promotions/isListLoading",
      promotionsMeta: "promotions/meta",
      promotions: "promotions/list",
    }),
    startDateFormatted: {
      get() {
        return this.formatDate(this.options.start_date);
      },
      set() {
        this.options.start_date = null;
      },
    },
    endDateFormatted: {
      get() {
        return this.formatDate(this.options.end_date);
      },
      set() {
        this.options.end_date = null;
      },
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (!this.isVisibleDialog) {
          await this.$store
            .dispatch("promotions/list", {
              ...params,
              status: "done",
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "Promotions not found");
            });
        }
      }, 1000),
      deep: true,
    },
  },

  data() {
    return {
      options: {},
      index: 0,
      promotion_types: promotionTypes,
      promotion_statuses: promotionStatues,
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_date_menu: false,
      end_date_menu: false,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: true,
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("start_date"),
          align: "start",
          sortable: true,
          value: "start_date",
        },

        {
          text: this.$t("end_date"),
          align: "start",
          sortable: true,
          value: "end_date",
        },
        {
          text: this.$t("type"),
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: this.$t("promotions.amount") + " %",
          align: "start",
          sortable: false,
          value: "discount",
        },
        {
          text: this.$t("status"),
          value: "status",
          align: "true",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    viewItem(item) {
      this.$router.push({
        name: "promotions.view",
        params: { id: item.id },
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async loadPromotions() {
      if (!this.isVisibleDialog) {
        await this.$store
          .dispatch("promotions/list", {
            ...this.options,
            status: "done",
          })
          .catch(() => {
            this.$store.dispatch("alerts/error", "Promotions not found");
          });
      }
    },
  },
};
</script>
